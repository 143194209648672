var $ = require("jquery");

import "@fortawesome/fontawesome-pro/js/fontawesome.js";
import "@fortawesome/fontawesome-pro/js/solid.js";
import "@fortawesome/fontawesome-pro/js/regular.js";

jQuery(function ($) {


    /* --- Mobile Navigation toggle --- */

    $(".mobile-nav-btn, .close-btn button ").on("click", function (e) {
        $(".mobile-nav-btn").toggleClass("active");
        $("body").toggleClass("hide-toggle");
        $(".mobile-menu").toggleClass("active");
        $("main").toggleClass("blur");
        e.preventDefault();
    });

    /* --- Page banner scroll down --- */

    $(".scroll-down").on("click", function (e) {
        $("html, body").animate({
            scrollTop: $(".page-banner-block").height()
        }, 1000);
        e.preventDefault();
    });

    /* --- Button scroll down --- */

    $(".is-style-scroll-down a").on("click", function (e) {
        var next = $(this).parents("section").next("section");
        $("html, body").animate({
            scrollTop: next.offset().top
        }, 1000);
        e.preventDefault();
    });


    /* --- Search toggle --- */

    $(".search-btn, .search-close").on("click", function (e) {
        $(".header .main-menu").toggleClass("hide");
        $(".header .search-btn").toggleClass("hide");
        $(".header .main-button").toggleClass("hide");
        $(".header .mobile-nav-btn").toggleClass("hide");
        $(".header .logo").toggleClass("hide");
        $(".search-form").toggleClass("active");
        e.preventDefault();
    });

    /* --- Mobile icon labels --- */

    $(".button-container li a span").each(function () {
        var text = $(this).text();
        $(this).parents(".menu-item").find(".mobile-label").html(text);
    });

    setTimeout(function () {
        $(".mobile-label").addClass("hide");
    }, 5000);


    /* --- Smooth anchor click scroll --- */

    $("a").on('click', function (event) {

        if (this.hash !== "") {

            var hash = this.hash;

            $('html, body').animate({
                scrollTop: $(hash).offset().top - 120
            }, 800, function () {
                window.location.hash = hash;
            });

            return false;
        }
    });

});